import React from "react"
import { graphql } from "gatsby"

import { BasicSEO } from "../components/seo"
import { MHPageProps } from "../types"
import { FooterNavigation } from "../components/FooterNavigation"
import { MHArticle } from "../components/MHArticle"
import { getExcerpt } from "../excerptHelper"

class MusicPageTemplate extends React.Component<MHPageProps> {
  render() {
    const post = this.props.data.mdx

    const { previous, next } = this.props.pageContext

    return (
      <>
        <BasicSEO
          social={post.social}
          excerpt={getExcerpt(post)}
          ogImage={post.frontmatter.image}
          withPubDate={true}
        />

        <div className="oneColumn n-type-larger">
          <MHArticle
            title={post.frontmatter.title}
            date={post.frontmatter.date}
            updatedDate={post.frontmatter.updatedDate}
            body={post.body}
            asArticle
          >
            {post.frontmatter.soundcloud ? (
              <p>
                ❤️&nbsp;
                <a
                  href={post.frontmatter.soundcloud}
                  target="blank"
                  rel="noopener"
                >
                  Like <em>{post.frontmatter.title}</em> on SoundCloud
                </a>
              </p>
            ) : null}
          </MHArticle>

          <FooterNavigation
            previous={previous}
            next={next}
            tags={post.frontmatter.tags}
          />
        </div>
      </>
    )
  }
}

export default MusicPageTemplate

export const pageQuery = graphql`
  query MusicPageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      ...BasicSocial
      ...MHExcerpt
      body
      frontmatter {
        title
        date
        updatedDate
        soundcloud
        tags
        image {
          childImageSharp {
            ...SocialImageResize
          }
        }
      }
    }
  }
`
